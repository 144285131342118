// src/routes/RouteWrapper.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { validateToken } from '../services/api';
import CustomerForm from '../components/CustomerForm';
import InvalidTokenMessage from '../components/InvalidTokenMessage';
import TimeoutMessage from '../components/TimeoutMessage';

const RouteWrapper = () => {
  const { token } = useParams();
  const [ formType, setFormType ] = useState(null)

  useEffect(() => {
    validateToken(token).then(setFormType)
  }, [])

  return <TimeoutMessage />
  // return formType === null ? null : (formType === 'assemblea' ? <CustomerForm /> : <InvalidTokenMessage />);
};

export default RouteWrapper;