import React from 'react';
import { Container, Button, Typography } from '@mui/material';
import Header from './Header'
import Footer from './Footer'

const TimeoutMessage = () => (
  <div>
    {/* <p>Purtroppo, non puoi accedere direttamente a questo modulo. Ti preghiamo di provare ad accedervi tramite il link fornito via email.</p> */}
    <Header /> {/* This will render the header at the top */}
    <Container maxWidth="sm"> {/* Controls the max width for adaptive design */}
    <Typography variant='subtitle1'>
          Carissimi Associati ed Amici,<br /><br />
          vi informiamo che abbiamo raggiunto la data limite per accettare nuove richieste di partecipazione alla nostra Assemblea Generale. Pertanto, il modulo per inviare le iscrizioni non è più disponibile.<br />
          Siamo lieti del grande interesse mostrato e desideriamo ringraziarvi per il vostro entusiasmo. Ogni anno il vostro supporto rende questo appuntamento un evento speciale e non vediamo l’ora di viverlo insieme ancora una volta.<br />
          Per qualsiasi domanda o necessità potrete contattare i nostri uffici di zona.
    </Typography>
    </Container>
    <Footer />
  </div>
);

export default TimeoutMessage;